import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";

import LogoPlusImg from "../../../../../assets/CLIKKLE PLUS LOGO.png";
import RHFTextField from "../../../../../components/rhf-textField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import useWidth from "../../../../../hooks/useSize";
import { toast } from "react-toastify";
import { useReferrer } from "../../../../../hooks/Authorize";
import RHFCheckbox from "../../../../../components/rhf-checkbox";

const SignInDetails = () => {
  const width = useWidth();
  const navigate = useNavigate();
  const  referred_by = useReferrer()
  const [isShowPassword, setIsShowPassword] = useState(false);
  const validationSchema = Yup.object({
    username: Yup.string()
      .matches(/^[a-zA-Z0-9\s]+$/, "Only alphabets are allowed")
      .min(2)
      .max(25)
      .required("Required Field"),
    password: Yup.string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Require a mix of uppercase, lowercase, numbers, and special characters."
    )
    .min(8)
    .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, action) => {
      console.log(values);
      var formData = new FormData();
      formData.append("email", values.username + '@clikkmail.com');
      let res = await check_email(formData);
      console.log('res', res)
      if (res) {


        // sessionStorage.setItem("registerBusiness6Screen", JSON.stringify(values));
        // navigate("/captcha-business");

        let registerBusiness1Screen = sessionStorage.getItem("registerBusiness1Screen");
        let registerBusiness2Screen = sessionStorage.getItem("registerBusiness2Screen");
        let registerBusiness3Screen = sessionStorage.getItem("registerBusiness3Screen");
        let registerBusiness4Screen = sessionStorage.getItem("registerBusiness4Screen");
        let registerBusiness5Screen = sessionStorage.getItem("registerBusiness5Screen");
        console.log(registerBusiness1Screen)
        console.log(registerBusiness2Screen)
        console.log(registerBusiness3Screen)
        console.log(registerBusiness4Screen)
        console.log(registerBusiness5Screen)
        if (registerBusiness1Screen &&
          registerBusiness2Screen &&
          registerBusiness3Screen &&
          registerBusiness4Screen &&
          registerBusiness5Screen) {
          registerBusiness1Screen = JSON.parse(registerBusiness1Screen);
          registerBusiness2Screen = JSON.parse(registerBusiness2Screen);
          registerBusiness3Screen = JSON.parse(registerBusiness3Screen);
          registerBusiness4Screen = JSON.parse(registerBusiness4Screen);
          registerBusiness5Screen = JSON.parse(registerBusiness5Screen);



          let reqBody = {

            firstName: registerBusiness2Screen.firstName,
            lastName: registerBusiness2Screen.lastName,
            phoneNumber: registerBusiness2Screen.phoneNumber,
            recoveryEmail: registerBusiness2Screen.email,
            referred_by: referred_by,
            password: values.password,
            accountType: "business",
            email: values.username + '@clikkmail.com',
            username: values.username,

            business: {
              businessName: registerBusiness1Screen.businessName,
              numberOfEmployees: registerBusiness1Screen.numberOfEmployees,
              region: registerBusiness1Screen.region,
              domain: registerBusiness3Screen.domain,
              streetAddress: registerBusiness4Screen.streetAddress,
              streetAddressLine2: registerBusiness4Screen.streetAddressLine2,
              city: registerBusiness4Screen.city,
              LocalGovernmentArea: registerBusiness4Screen.LocalGovernmentArea,
              postalCode: registerBusiness5Screen.postalCode,
              state: registerBusiness5Screen.state,
              businessPhoneNumber: registerBusiness5Screen.businessPhoneNumber,
            }
          }


          pushToServer(reqBody);

        } else {
          toast.error("Please Fill all details correctly");
          navigate("/number-of-employee");
        }
      } else {
        toast.error('Email exists');
      }

      action.resetForm();

    },
  });

  const pushToServer = async (reqData) => {
    // console.log('process.env',process.env)
    try {
      const response = await fetch(
        // "http://localhost:5000/api/auth/register",
        // "http://clicklewebapp.medtronix.world/api/auth/email",
        "https://accounts.clikkle.com:5000/api/auth/register",
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(reqData),
        }
      );

      const responseData = await response.json();
      if (responseData.success) {
        console.log('registered', responseData)
        localStorage.setItem("user", JSON.stringify(responseData));
        navigate("/business-enter-code");
        //navigate("/login_2");
      } else {
        //setErrorMessage(responseData.message);
        toast.error(responseData.error);
        console.error(responseData.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const styles = {
    registerWrap: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "#fff",
      height: "100dvh",
    },
    registerContent: {
      marginTop: "10px",
      maxWidth: width > 768 ? "448.8px" : "100%",
      minHeight: width > 768 ? "500px" : "100%",
      border: width > 768 ? "1px solid #8C8C8C" : "none",
      borderRadius: width > 768 ? "12px" : "none",
      // boxShadow:
      //   width > 768 ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" : "none",
      px: "15px",
      // py: "25px",
      backgroundColor: width > 768 ? "#121212" : null,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignContent: "space-between",
      flexDirection: "column",
    },
    heading: {
      fontSize: "24px",
      fontWeight: 600,
    },
    label: {
      fontSize: "12px",
      fontWeight: 400,
      color: "#f5f4f4",
      py: "3px",
      px: "6px",
    },
    emailInputAdornment: {
      fontSize: "13px",
      color: "#605f5f",
      fontWeight: 600,
      letterSpacing: 0.8,
      pt: 0.5,
    },
    forgotPassword: {
      color: "#1470B6",
      fontWeight: 700,
      fontSize: "14px",
    },
    register: {
      color: "#1470B6",
      fontWeight: 700,
      fontSize: "14px",
      textDecoration: "none",
    },
    button: {
      textDecoration: "none",
      backgroundColor: "#1472b8",
      padding: "6px 16px",
      color: "#fff",
      borderRadius: "4px",
      fontSize: "0.875rem",
      fontWeight: 600,
      textTransform: "uppercase",
      "&:hover": {
        background: "#1472b8",
      },
    },
    desc: {
      fontSize: "16px",
      fontWeight: 600,
      color: "#7e7878",
      py: 1,
      px: 2,
    },
  };

  const check_email = async (formData) => {
    // console.log('process.env',process.env)
    try {
      const response = await fetch(
        // "http://localhost:5000/api/auth/check_email",
        // "http://clicklewebapp.medtronix.world/api/auth/email",
        "https://accounts.clikkle.com:5000/api/auth/check_email",
        {
          method: "POST",
          body: formData,
        }
      );

      // if (!response.ok) {
      //   throw new Error("Network response was not ok");
      // }

      const responseData = await response.json();
      if (responseData.success) {
        console.log('registered', responseData)
        return true
        //navigate("/login_2");
      } else {
        // setErrorMessage(responseData.error);
        return false
        // console.error(responseData.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Box sx={styles.registerWrap}>
      <Box sx={styles.registerContent}>
        <Box sx={{ textAlign: "center" }}>
          <Box sx={{ textAlign: "center", paddingTop: "5px" }}>
            <img
              src={LogoPlusImg}
              alt="Logo"
              style={{ width: width > 768 ? "170px" : "170px" }}
            />
          </Box>
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Typography component="h2" sx={styles.heading}>
            How you'll sign in
          </Typography>
          <Typography component="h4" sx={styles.label}>
            You'll use your username to sign into your Clikkle Plus account and
            create your business email address
          </Typography>

          <form onSubmit={formik.handleSubmit} style={styles.formWrapper}>
            <Box>
              <Box>
                <RHFTextField
                  formik={formik}
                  variant="outlined"
                  name="username"
                  type="text"
                  placeholder="Username"
                  inputProps={{ maxLength: 64 }}
                  sx={{
                    border: "1px solid #8C8C8C",
                    borderRadius: "12px",
                  }}
                />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    padding: "0 10px",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: "#605f5f",
                      fontSize: "12px",
                    }}
                  >
                    {formik.values.username ? formik.values.username.length : 0}
                    /64
                  </Typography>
                </Box>
              </Box>
              <Box>
                <RHFTextField
                  formik={formik}
                  variant="outlined"
                  name="password"
                  type={isShowPassword ? "text" : "password"}
                  placeholder="Password"
                  controlSx={{ pt: 1 }}
                  inputProps={{ maxLength: 100 }}
                  sx={{ border: "1px solid #8C8C8C", borderRadius: "12px" }}
                />
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0 10px",
                  }}
                >
                    <RHFCheckbox
                  label="Show Password"
                  handleChange={() => setIsShowPassword(!isShowPassword)}
                />
                  <Typography
                    variant="subtitle2"
                    style={{ color: "#605f5f", fontSize: "12px" }}
                  >
                    {formik.values.password ? formik.values.password.length : 0}
                    /100
                  </Typography>
                </Box>
                {/* <Box
                  style={{
                    display: "flex",
                    justifyContent: "between",
                    padding: "0 10px",
                  }}
                >
            
                </Box> */}
                
              </Box>
              {/* legend fieldset */}
              <fieldset
                style={{
                  border: "2px solid #605f5f",
                  borderRadius: "12px",
                  margin: 0,
                  padding: 0,
                }}
              >
                <legend
                  style={{
                    textAlign: "left",
                    margin: "0 30px 10px 30px",
                    color: "#605f5f",
                    fontSize: "14px",
                    fontWeight: 600,
                    padding: "0 5px",
                  }}
                >
                  Discover Clikkle Plus
                </legend>
                <FormGroup sx={{ padding: "0 20px", color: "#605f5f" }}>
                  <FormControlLabel
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    control={<Checkbox style={{ color: "#605f5f" }} />}
                    label={
                      <Typography
                        variant="body2"
                        style={{
                          fontSize: "11px",
                          textAlign: "left",
                          width: "100%",
                        }}
                      >
                        Get Tips update offers and opportunities to share
                        feedback
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    control={<Checkbox style={{ color: "#605f5f" }} />}
                    label={
                      <Typography
                        variant="body2"
                        style={{
                          fontSize: "11px",
                          textAlign: "left",
                          width: "100%",
                        }}
                      >
                        Educate your users
                      </Typography>
                    }
                  />
                </FormGroup>
              </fieldset>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  pt: 2,
                }}
              >
                {/* Login Button */}
                <Button
                  type="submit"
                  variant="contained"
                  sx={styles.button}
                  disabled={!formik.isValid}
                >
                  next
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
        <Box sx={{ textAlign: "center", pt: 1 }}>
          <Typography component="p" sx={styles.desc}>
            Enter the AI realm with us, or join us with your login
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SignInDetails;
