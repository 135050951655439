import React, { useState } from "react";
import { Box, Button, InputAdornment, Typography } from "@mui/material";
import LogoImg from "../../../../../../assets/logo.png";
import RHFTextField from "../../../../../../components/rhf-textField";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import useWidth from "../../../../../../hooks/useSize";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const ForgotMail = () => {
  const width = useWidth();
  const [step, setStep] = useState(0)

  const validationSchema = Yup.object({
    email: Yup.string().required("Email is required").email("Email is invalid"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Create FormData object and append email field
      var formData = new FormData();
      formData.append("recoveryEmail", values.email);
      formData.append("type", "email");
      pushToServer(formData)
      // navigate("/full-name-email");
    },
  });

  const mobileValidationSchema = Yup.object({
    number: Yup.string()
      .required("Number is required")
      .matches(/^\d+$/, "Number must contain only digits")
      .min(10, "Number must be at least 10 characters long")
      .max(10, "Number cannot exceed 10 characters"),
  });

  const mobileFormik = useFormik({
    initialValues: {
      number: "",
    },
    validationSchema: mobileValidationSchema,
    onSubmit: (values, action) => {
      var formData = new FormData();
      formData.append("phoneNumber", values.number);
      formData.append("type", "phone");
      pushToServer(formData)
      action.resetForm();

    },
  });

  const pushToServer = async (formData) => {
    try {
      const response = await fetch(
        "https://accounts.clikkle.com:5000/api/auth/forgotMail",
        // "http://localhost:5000/api/auth/forgotMail",
        {
          method: "POST",
          body: formData,
        },

      );

      const responseData = await response.json();
      console.log("responseData", responseData)
      if (responseData.success) {
        toast.success("Email was sent!");
        setStep(2)
      } else {
        // setErrorMessage(responseData.error);
        toast.error(responseData.error);
        console.error(responseData.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const styles = {
    loginWrap: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "#fff",
      height: "100dvh",
    },
    loginContent: {
      marginTop: "10px",
      maxWidth: width > 768 ? "448.8px" : "100%",
      height: width > 768 ? "500px" : "100%",
      border: width > 768 ? "1px solid #8C8C8C" : "none",
      borderRadius: width > 768 ? "12px" : "none",
      // boxShadow:
      //   width > 768 ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" : "none",
      px: "35px",
      py: "35px",
      backgroundColor: "#121212",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formWrapper: {
      paddingTop: "50px",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignContent: "space-between",

      flexDirection: "column",
    },
    heading: {
      fontSize: "24px",
      fontWeight: 600,
    },
    label: {
      fontSize: "16px",
      fontWeight: 400,
      color: "#f5f4f4",
      py: 1,
    },
    emailInputAdornment: {
      fontSize: "13px",
      color: "#605f5f",
      fontWeight: 600,
      letterSpacing: 0.8,
      pt: 0.5,
    },
    forgotPassword: {
      color: "#1470B6",
      fontWeight: 700,
      fontSize: "14px",
    },
    register: {
      color: "#1470B6",
      fontWeight: 700,
      fontSize: "14px",
      textDecoration: "none",
    },
    button: {
      backgroundColor: "#1472b8",
      padding: "6px 16px",
      color: "#fff",
      borderRadius: "4px",
      fontSize: "0.875rem",
      fontWeight: 600,
      textTransform: "uppercase",
      "&:hover": {
        background: "#1472b8",
      },
    },
    desc: {
      fontSize: "16px",
      fontWeight: 600,
      color: "#7e7878",
      pt: 3,
      px: 2,
    },
  };
  return (
    <Box sx={styles.loginWrap}>
      <Box sx={styles.loginContent}>
        <Box sx={{ textAlign: "center" }}>
          <Box sx={{ textAlign: "center" }}>
            <img
              src={LogoImg}
              alt="Logo"
              style={{ width: width > 768 ? "8%" : "12%" }}
            />
          </Box>
        </Box>
        {step == 0 &&
          <Box sx={{ pt: 4, textAlign: "center" }}>
            <Typography component="h2" sx={styles.heading}>
              Forgot Clikkmail
            </Typography>
            <Typography component="h4" sx={{ ...styles.label, pt: 2 }}>
              Enter your recovery recovery email
            </Typography>
            <form onSubmit={formik.handleSubmit}  style={styles.formWrapper} >
              <Box  >
                <RHFTextField
                  formik={formik}
                  name="email"
                  type="text"
                  placeholder="Enter recovery email"
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    pt: 2.8,
                  }}
                >
                  <Link
                    onClick={()=> { setStep(1)}}
                    sx={styles.buttonTransparent}
                    style={{
                      textDecoration: "none",
                      color: "#1472b8",
                      padding: "6px 16px",
                      borderRadius: "4px",
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      textTransform: "uppercase",
                      "&:hover": {
                        background: "#1472b8",
                      },
                    }}
                  >
                    Try Another Method
                  </Link>
                  <Button sx={styles.button} type="submit">
                    Next
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>}
        {step == 1 &&
          <Box sx={{ pt: 4, textAlign: "center" }}>
            <Typography component="h2" sx={styles.heading}>
              Forgot Clikkmail
            </Typography>
            <Typography component="h4" sx={{ ...styles.label, pt: 2 }}>
              Enter your recovery number or recovery email
            </Typography>
            <form onSubmit={mobileFormik.handleSubmit} style={styles.formWrapper}>
              <Box >
                <RHFTextField
                  formik={mobileFormik}
                  name="number"
                  type="text"
                  placeholder="Enter phone number"
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-end",
                    pt: 2.8,
                  }}
                >
                  <Link
                    onClick={()=> { setStep(0)}}
                    sx={styles.buttonTransparent}
                    style={{
                      textDecoration: "none",
                      color: "#1472b8",
                      padding: "6px 16px",
                      borderRadius: "4px",
                      fontSize: "0.875rem",
                      fontWeight: 600,
                      textTransform: "uppercase",
                      "&:hover": {
                        background: "#1472b8",
                      },
                    }}
                  >
                    Try Another Method
                  </Link>
                  <Button sx={styles.button} type="submit">
                    Next
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>

        }
        {step == 2 &&
          <Box sx={{ pt: 2, textAlign: "center" }}>
            <Typography component="h2" sx={styles.heading}>
              Account Details Sent to Your Recovery Email
            </Typography>
            <Typography component="h4" sx={{ ...styles.label, pt: 6 }}>
              your account details have been sent to your recovery email address.
              Please check your inbox for an email from us containing all the necessary information.
            </Typography>

            <Box sx={styles.formWrapper}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Link to="/login" style={styles.button}>
                  Next
                </Link>
              </Box>
            </Box>
          </Box>

        }
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography component="p" sx={styles.desc}>
          Enter the AI realm with us, or join us with your login
        </Typography>
      </Box>
    </Box>
  );
};

export default ForgotMail;
