import React, { useState, useEffect } from "react";
import { Box, Button, Avatar, Typography, InputAdornment } from "@mui/material";
import LogoImg from "../../../../../../assets/logo.png";
import RHFTextField from "../../../../../../components/rhf-textField";
import { useFormik } from "formik";
import * as Yup from "yup";
import RHFCheckbox from "../../../../../../components/rhf-checkbox";
import useWidth from "../../../../../../hooks/useSize";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const ForgotPasswordEmail_1 = () => {
  const [step , setStep ] =  useState(0);
  const localStorageData = JSON.parse(localStorage.getItem("formData"));
  const width = useWidth();
  const navigate = useNavigate();
  const [timer, setTimer] = useState(180); // Initial timer value in seconds
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const  [ recoveryData  , setRecoveryData] =  useState({})
  const [isShowPassword, setIsShowPassword] = useState(false);

  const styles = {
    loginWrap: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      color: "#fff",
      height: "100dvh",
    },
    loginContent: {
      marginTop: "10px",
      maxWidth: width > 768 ? "448.8px" : "100%",
      height: width > 768 ? "500px" : "100%",
      border: width > 768 ? "1px solid #8C8C8C" : "none",
      borderRadius: width > 768 ? "12px" : "none",
      // boxShadow:
      //   width > 768 ? "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px" : "none",
      px: "35px",
      py: "35px",
      backgroundColor: "#121212",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
    formWrapper: {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignContent: "space-between",
      flexDirection: "column",
    },
    heading: {
      fontSize: "24px",
      fontWeight: 600,
    },
    label: {
      fontSize: "16px",
      fontWeight: 400,
      color: "#f5f4f4",
      py: 1,
    },
    emailInputAdornment: {
      fontSize: "13px",
      color: "#605f5f",
      fontWeight: 600,
      letterSpacing: 0.8,
      pt: 0.5,
    },
    forgotPassword: {
      color: "#1470B6",
      fontWeight: 700,
      fontSize: "14px",
    },
    register: {
      color: "#1470B6",
      fontWeight: 700,
      fontSize: "14px",
      textDecoration: "none",
    },
    button: {
      backgroundColor: "#1472b8",
      padding: "6px 16px",
      color: "#fff",
      borderRadius: "4px",
      fontSize: "0.875rem",
      fontWeight: 600,
      textTransform: "uppercase",
      "&:hover": {
        background: "#1472b8",
      },
    },
    buttonTransparent: {
      color: "#1472b8",
      fontSize: width > 768 ? "16px" : "10px",
      fontWeight: 600,
      textTransform: "capitalize",
    },
    desc: {
      fontSize: "16px",
      fontWeight: 600,
      color: "#7e7878",
      pt: 3,
      px: 2,
    },
    avatarWrapper: {
      display: "flex",
      alignItems: "center",

      gap: "10px",
    },
  };

  /// step  1  
  const step1validationSchema = Yup.object({
    email: Yup.string()
      .matches(/^[a-zA-Z0-9]+$/, "Only numbers & alphabets are allowed")
      .min(2)
      .max(25)
      .required("Required Field"),
  });

  const  step1Formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: step1validationSchema,
    onSubmit: (values, action) => {
        // handelForgetPassword();
        var formData = new FormData();
        formData.append("email", values.email + "@clikkmail.com");
        setRecoveryData({ ...recoveryData , email:values.email})
        handelForgetPassword(formData ,()=>{
          setStep(1);
          action.resetForm();
        })
    },
  });

  const handelForgetPassword  = async(formData ,cb)=>{
    try {
      const response = await fetch(
        "https://accounts.clikkle.com:5000/api/auth/forgotPassword",
        // "http://localhost:5000/api/auth/forgotPassword",
        {
          method: "POST",
          body: formData,
        },

      );

      const responseData = await response.json();
      console.log("responseData",responseData)
      if (responseData.success) {
        toast.success("Otp sent to your recovery Email")
        cb();

      } else {
        // setErrorMessage(responseData.error);
        toast.error(responseData.error);
        console.error(responseData.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }

  }

    // step 2
    // Event handler for Resend Code button
  const handleResendCode = () => {
      setTimer(180); // Reset timer
      setIsResendDisabled(true); // Disable resend button
      handelForgetPassword(recoveryData.email);
      // Here you can trigger the resend action if needed
  };
  // Decrease timer every second
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimer((prevTimer) => {
        if (prevTimer === 1) {
          setIsResendDisabled(false); // Enable resend button when timer reaches 1 second
        }
        return prevTimer > 0 ? prevTimer - 1 : prevTimer;
      });
    }, 1000);

    // Clear interval when component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const step2ValidationSchema = Yup.object({
    number: Yup.string()
      .required("Number is required")
      .matches(/^\d+$/, "Number must contain only digits")
      .min(6, "Number must be at least 6 characters long")
      .max(6, "Number cannot exceed 6 characters"),
  });

  const step2formik = useFormik({
    initialValues: {
      number: "",
    },
    validationSchema: step2ValidationSchema,
    onSubmit: async(values, action) => {
      action.resetForm();
      setRecoveryData({...recoveryData , otp : values.number});
      setStep(2)      
    },
  });

   // step 3

   const handelResetPassword = async(formData ,cb)=>{
    try {
      const response = await fetch(
        "https://accounts.clikkle.com:5000/api/auth/resetPassword",
        // "http://localhost:5000/api/auth/resetPassword",
        {
          method: "PUT",
          body: formData,
        },

      );

      const responseData = await response.json();
      console.log("responseData",responseData)
      if (responseData.success) {
        toast.success("Password Reset Success")
        cb();

      } else {
        toast.error(responseData.error);
        console.error(responseData.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }

  }
  const step3ValidationSchema = Yup.object({
    password: Yup.string()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
        "Require a mix of uppercase, lowercase, numbers, and special characters."
      )
      .min(8)
      .required("Password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const step3formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: step3ValidationSchema,
    onSubmit: (values, action) => {
      console.log(values);

      var formData = new FormData();
      formData.append("email", recoveryData.email + "@clikkmail.com");
      formData.append("otp", recoveryData.otp);
      formData.append("password", values.password);

      handelResetPassword(formData ,()=>{
       action.resetForm();
       setStep(3)
      })
     
    },
  });

  function formatTime(seconds) {
    // Calculate the minutes and seconds
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    // Add leading zero if necessary
    const formattedMinutes = minutes.toString().padStart(2, '0');
    const formattedSeconds = remainingSeconds.toString().padStart(2, '0');

    // Combine the formatted minutes and seconds
    return `${formattedMinutes}:${formattedSeconds}`;
}

  return (
    <Box sx={styles.loginWrap}>
      <Box sx={styles.loginContent}>
        <Box sx={{ textAlign: "center" }}>
          <Box sx={{ textAlign: "center" }}>
            <img
              src={LogoImg}
              alt="Logo"
              style={{ width: width > 768 ? "8%" : "12%" }}
            />
          </Box>
        </Box>

        {step == 0 && 
         <Box sx={{ pt: 2, textAlign: "center" }}>
          <Typography component="h2" sx={styles.heading}>
            Account Recovery
          </Typography>
          <Typography component="h4" sx={styles.label}>
            To ensure the safety of your account, Clikkle wants to verify that
            it's really you
          </Typography>
          <Box
            sx={styles.avatarWrapper}
            style={{
              border: "1px solid #1472b8",
              borderRadius: "12px",
              padding: "6px",
              width: "fit-content",
              margin: "20px auto 0 auto",
            }}
          >
            <Avatar
              style={{
                backgroundColor: "#1472b8",
                width: "20px",
                height: "20px",
              }}
            >
             {  "U" }  
            </Avatar>
            <Box>
              {/* Your email */}
              <Typography
                component="p"
                sx={styles.label}
                style={{
                  fontSize: "14px",
                }}
              >
                yourname@clikkmail.com
              </Typography>
            </Box>
          </Box>

          <Typography
            component="h4"
            sx={styles.label}
            style={{ margin: "20px 0" }}
          >
            Confirm the email you registered with *****@clikkmail.com
          </Typography>
          <form onSubmit={step1Formik.handleSubmit} style={styles.formWrapper}>
            <Box>
               <RHFTextField
                formik={step1Formik}
                name="email"
                placeholder="Enter Email Address"
                type="text"
                inputPorps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography sx={styles.emailInputAdornment}>
                        @clikkmail.com
                      </Typography>
                    </InputAdornment>
                  ),
                }}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  pt: 2.8,
                }}
              >
           
                {/* <Link
                  to="/forgot-password-phone-1"
                  sx={styles.buttonTransparent}
                  style={{
                    textDecoration: "none",
                    color: "#1472b8",
                    padding: "6px 16px",
                    borderRadius: "4px",
                    fontSize: "0.875rem",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    "&:hover": {
                      background: "#1472b8",
                    },
                  }}
                >
                  Try Another Method
                </Link> */}
                <Button sx={styles.button} type="submit">
                  Next
                </Button>
              </Box>
            </Box>
          </form>
         </Box>
        }
        {step ==1 && 
         <Box sx={{ pt: 2, textAlign: "center" }}>
         <Typography component="h2" sx={styles.heading}>
           Enter Code
         </Typography>
         <Typography component="h4" sx={styles.label}>
           Enter the code sent to your email
         </Typography>
         <form onSubmit={step2formik.handleSubmit} style={styles.formWrapper}>
           <Box>
             <RHFTextField
               formik={step2formik}
               name="number"
               type="text"
               placeholder="Enter Code"
             />

             <Box
               sx={{
                 display: "flex",
                 justifyContent: "flex-start",
                 alignItems: "flex-start",
                 pt: 2,
               }}
             ></Box>
             <Box
               sx={{
                 display: "flex",
                 justifyContent: "space-between",
                 alignItems: "center",
                 pt: 2,
               }}
             >
               <Button
                 onClick={handleResendCode}
                 disabled={isResendDisabled}
                 sx={styles.forgotPassword}
               >
                 Resend Code
               </Button>
               <Typography component="h2" sx={styles.forgotPassword}>
                {formatTime(timer)}
               </Typography>
             </Box>

             <Box
               sx={{
                 display: "flex",
                 justifyContent: "flex-end",
                 alignItems: "flex-end",
                 pt: 2.8,
               }}
             >
               <Button sx={styles.button} type="submit">
                  Next
                </Button>
             </Box>
           </Box>
         </form>
         </Box>}
        {step == 2 &&      
          <Box sx={{ pt: 2, textAlign: "center" }}>
          <Typography component="h2" sx={styles.heading}>
            Create Password
          </Typography>
          <Typography component="h4" sx={styles.label}>
            Use your Clikkle Account
          </Typography>
          <form onSubmit={step3formik.handleSubmit} style={styles.formWrapper}>
            <Box>
              <RHFTextField
                formik={step3formik}
                name="password"
                type={isShowPassword ? "text" : "password"}
                placeholder="Enter Password"
              />
              <RHFTextField
                formik={step3formik}
                name="confirmPassword"
                type={isShowPassword ? "text" : "password"}
                placeholder="Confirm Password"
                controlSx={{ pt: 2 }}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  pt: 2,
                }}
              >
                <RHFCheckbox
                  label="Show Password"
                  handleChange={() => setIsShowPassword(!isShowPassword)}
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  pt: 2.8,
                }}
              >
                <Button sx={styles.button} type="submit">
                  Next
                </Button>
              </Box>
            </Box>
          </form>
          </Box>}
          {step == 3 &&      
                    <Box sx={{ pt: 2, textAlign: "center" }}>
                    <Typography component="h2" sx={styles.heading}>
                      Review Your Account Info
                    </Typography>
                    <Typography component="h4" sx={styles.label}>
                      You can use this info when you need to sign in
                    </Typography>
                      <Box>
                        <Box sx={{...styles.avatarWrapper ,  py:4 }}>
                          <Avatar
                            style={{ backgroundColor: "#1472b8", marginBottom: "10px" }}
                          >
                            {recoveryData?.email?.slice(0, 2)?.toUpperCase() || "U"}
                          </Avatar>
                          <Box>
                            {/* Your Name */}
                            <Typography
                              component="p"
                              sx={styles.label}
                              style={{
                                textAlign: "left",
                                fontSize: "22px",
                                fontWeight: 600,
                                lineHeight: "10px",
                              }}
                            >
                            {recoveryData.email}
                            </Typography>
                            {/* Your email */}
                            <Typography component="p" sx={{...styles.label,  pt: 2.8 }}>
                            {recoveryData.email}@clikkmail.com
                            </Typography>
                          </Box>
                        </Box>     
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                            pt: 2.8,
                          }}
                        >
                          <Link
                            to='/login'
                            sx={styles.button}
                            style={{
                              textDecoration: "none",
                              backgroundColor: "#1472b8",
                              padding: "6px 16px",
                              color: "#fff",
                              borderRadius: "4px",
                              fontSize: "0.875rem",
                              fontWeight: 600,
                              textTransform: "uppercase",
                              "&:hover": {
                                background: "#1472b8",
                              },
                            }}
                          >
                            Next
                          </Link>
                        </Box>
                      </Box>
                  </Box>
          }
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography component="p" sx={styles.desc}>
          Enter the AI realm with us, or join us with your login
        </Typography>
      </Box>
    </Box>
  );
};

export default ForgotPasswordEmail_1;
